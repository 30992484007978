import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import cn from 'classnames';

import { useGetPresentationsQuery } from 'store/api';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import Card from 'components/card';
import { CardSize } from 'components/card/type';
import NoDataContainer from 'components/no-data-container';
import Preloader from 'components/preloader';

import ClampedEllipsis from 'ui-kit/clamped-ellipsis';

import { getPresentationLink } from 'utils/link';

import styles from './presentations.module.scss';

export const Presentations: FC = () => {
  const { query } = useCustomRouter();

  const { sort, order, text } = query;

  const {
    data: presentations,
    isLoading,
    isFetching,
  } = useGetPresentationsQuery({
    sort,
    order,
    text,
  });

  const isNoData = presentations && !presentations.length;

  if (isLoading || isFetching) return <Preloader />;

  if (isNoData) return <NoDataContainer />;

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {presentations?.map((presentation) => {
          const { name, id, preview } = presentation;

          const href = getPresentationLink(id);

          return (
            <Link
              key={id}
              href={{
                ...href,
                query: {
                  ...(Object(href.query) ?? {}),
                  ...query,
                },
              }}
              passHref>
              <li className={cn(styles.item, 'pointer')}>
                <Card classes={styles.itemImage} size={CardSize.ExtraSmall}>
                  <Image width={163} height={122} src={preview} unoptimized />
                </Card>

                <ClampedEllipsis maxWidth={200} lineClamp={2}>
                  {name}
                </ClampedEllipsis>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default Presentations;
