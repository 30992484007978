import Head from 'next/head';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { OrganizationState } from 'reducers/organizationSlice';
import { RootState } from 'store';

import { HeadPageProps } from './type';

const HeadPage: FC<HeadPageProps> = (props) => {
  const { children, nameSection } = props;

  const { name } = useSelector<RootState, OrganizationState>(
    ({ organization }) => organization,
  );

  const mainText = 'maven.detailing';

  const titleCompany = useMemo(() => {
    if (!name) return mainText;

    return `${mainText} - ${name}`;
  }, [name]);

  const titleFull = useMemo(() => {
    if (!nameSection) return titleCompany;

    return `${nameSection} - ${titleCompany}`;
  }, [titleCompany]);

  return (
    <Head>
      <title>{titleFull}</title>

      {children}
    </Head>
  );
};

export default HeadPage;
